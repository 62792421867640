import React from "react";
import GeneralHero from "../components/general/generalHero";
import EasySolutions from "../components/services/easySolutions";
import ServicesCard from "../components/home/servicesCard";
import Topic from "../components/home/topic";
// import ItSolutions from "../components/services/itSolution";
import Funfact from "../components/general/funfact";
import PopSolutions from "../components/services/popSolution";
import BrandCarousel from "../components/general/brandCarousel";
import CallToAction from "../components/general/callToAction";

const Services = () => {
  return (
  <>

    <GeneralHero bgText={"services"} theme={"Services"} subTheme={"services"} />

    {/* <ItSolutions /> */}
    <section className="we-provide-solutions">
            <div className="container">
                <Topic head={"HOW WE CAN HELP YOU"} info={"We Provide Best IT Solutions For Your Business"} />
    <div className="row text-center">
            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-1.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Branding"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-2.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Web Development"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-3.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Mobile App Development"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-4.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Graphics designing"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-5.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Tech consulting"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-6.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Social Media Managment"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-7.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Co-working spaces"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-8.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Digital skills training"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-9.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Digital Literacy"} />
        </div>
        </div>
        </section>

    <EasySolutions />    

    <Funfact />

    <PopSolutions />

    <BrandCarousel />

    <CallToAction />

  </>
  );
}

export default Services;