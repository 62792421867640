import React from "react";
import SubHeroBanner from "./subHeroBanner";

const SubHeroFirst = () => {
  return (
    <div className="row">
        <div className="col-lg-6 col-12 pr-5">
            <div className="section-title mb-20">
                <h1>WHO WE ARE</h1>
            </div>
            <p className="pr-lg-5">We are a team of creative tech innovators, equipped with a high skill-set developed over decades of experience not only in information technology but also in business processes across a range of industry sectors.<br />
            Relationship: We cultivate professional relationships with our clients to provide effective and reliable information technology solutions for their needs. We work for you, we can also work with you.<br />
            Creativity: Creativity is our nature, the primary key to our success, our ability to introduce order into the randomness of nature and build amazing solutions. <br />
            Consistency in excellence: Our team consistently delivers state-of-the-art solutions in various areas such as, School management systems, Custom enterprise ERPS, integrated business solutions, system applications, product development, and Internet/Intranet applications.</p>
        </div>

        <SubHeroBanner />

    </div>
  );
}

export default SubHeroFirst;