import React from "react";
import ServiceSectionContainer from "./serviceSectionContainer";
import AboutUsWrapper from "./aboutUsWrapper";
import AboutWrapper from "./aboutWrapper";
import BrandCarousel from "../general/brandCarousel";
import CallToAction from "../general/callToAction";
import SubHero from "./subHero";
// import TestimonialWrapper from "./testimonialWrapper";

const Second = () => {
    return(
        <>
            <div className="content-area">
                <SubHero />

                <BrandCarousel />

                <AboutWrapper />

                <ServiceSectionContainer />

                <AboutUsWrapper />

                {/* <TestimonialWrapper /> */}          

                <CallToAction />

            </div>
        </>
    )
}

export default Second;