import React, { useState } from "react";
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Products from './pages/products';
import Services from './pages/services';
import Refundpolicy from './pages/refund_policy';
// import Hackathon from './pages/hackathon';
// import Exhibit from './pages/exhibit';
import Contact from './pages/Contact';
import Header from './components/header';
import Footer from './components/footer';
import NotFound from './components/NotFound';
import "./assets/img/favicon.png";
import "./assets/css/icons.css";
import "./assets/css/animate.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/metismenu.css";
import "./assets/css/owl.theme.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/style.css"



function App() {

    const [showModal, setShowModal] = useState(true);
  
    const closeModal = () => {
      setShowModal(false);
    };
  
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/products" element={<Products />}/>
        <Route path="/services" element={<Services />}/>
        <Route path="/refund_policy" element={<Refundpolicy />}/>
        <Route path="/contact" element={<Contact />}/>
        {/* <Route path="/hackathon" element={<Hackathon />}/> */}
        {/* <Route path="/exhibit" element={<Exhibit />}/> */}
        <Route path="/*" element={<NotFound />}/>
      </Routes>
      <Footer />
      {showModal && (
  <div className="modal-overlay">
    <div className="modal-content">
      <img
        src="assets/img/academy-ath.webp"
        alt="techflock2023"
        className="modal-image"
      />
      <p>Learn a tech skill in 2024</p>
      <a href="http://academy.abiatechhub.com/" className="theme-btn">Get Started</a>
      <button onClick={closeModal} className="btn btn-secondary" style={{ marginTop: '10px' }}>Close</button>
    </div>
  </div>
)}

    </Router>
  );
}

export default App;
