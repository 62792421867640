import React from "react";

const InfoTipCard = (props) => {
  return (
    <div className="col-lg-4 col-md-6 col-12">
        <div className="single-features-item">
            <div className="icon">
                <i className="flaticon-speech-bubble"></i>
            </div>
            <div className="content">
                <h3 className="darkblue">{props.caption}</h3>
                <p>{props.tip}</p>
            </div>
        </div>
    </div>
  );
};

export default InfoTipCard;