import React from "react";

const CallToAction = () => {
  return (
    <section className="cta-banner">
        <br />
        <br />
        <div className="container-fluid bg-cover section-bg" style={{backgroundImage: "url('assets/img/cta_bg1.png')"}}>
            <div className="cta-content">
                <div className="row align-items-center">
                    <div className="col-xl-7 text-white col-12 text-center text-xl-left">
                    <p style={{ fontSize: '36px', lineHeight: '1.2em' }}>
                        Ready To Get Free Consultations For <br/> Any Kind Of IT Solutions?
                    </p>
                    </div>
                    <div className="col-xl-5 col-12 text-center text-xl-right">
                        <a href="/contact" className="theme-btn mt-4 mt-xl-0">Get a quote <i className="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <br />
    </section>
  );
};

export default CallToAction;