import React from "react";
import ServiceTipsCard from "./serviceTips-Card";

const ServiceTips = () => {
  return (
    <div className="row">
        <ServiceTipsCard id={"01"} caption={"IT Consultancy"} detail={"Professional advice on technology-related matters for businesses/individuals."} />
        
        <ServiceTipsCard id={"02"} caption={"Digital Services"} detail={"Technology-based services to establish/maintain an online presence."} />
        
        <ServiceTipsCard id={"03"} caption={"Excellent Support"} detail={"Promptly addressing customer concerns and ensuring satisfaction."} />

        <ServiceTipsCard id={"04"} caption={"Expertise"} detail={"High-level knowledge for customized and effective business solutions."} />
    </div>
  );
};

export default ServiceTips;