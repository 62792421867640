import React from "react";
import BrandImgContainer from "./brandImgContainer";

const BrandCarousel = () => {
  return (
    <div className="client-brand-logo-wrap techex-landing-page pt-0">
        <div className="container">
            <div className="brand-carousel-active d-flex justify-content-between owl-carousel">
                <BrandImgContainer imgUrl={"assets/img/brand/4.webp"} />
                <BrandImgContainer imgUrl={"assets/img/brand/b4.webp"} />
                <BrandImgContainer imgUrl={"assets/img/brand/2.webp"} />
                <BrandImgContainer imgUrl={"assets/img/brand/1.webp"} />
                <BrandImgContainer imgUrl={"assets/img/brand/3.webp"} />
                <BrandImgContainer imgUrl={"assets/img/brand/ibm_1.webp"} />
                {/* <BrandImgContainer imgUrl={"assets/img/brand/b5.png"} /> */}
            </div>
        </div>
    </div>
  );
};

export default BrandCarousel;