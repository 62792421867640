import React from "react";
import ServicesCard from "./servicesCard";

const Services = () => {
  return (
    <div className="row text-center">
            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-8.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Digital skills training"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-2.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Web Development"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-3.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Mobile App Development"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-4.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Graphics designing"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-5.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Tech consulting"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-6.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Social Media Managment"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-7.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Co-working spaces"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-1.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Branding"} />

            <ServicesCard bgImgUrl={'assets/img/techex-landing-page/service-9.webp'} imgUrl={"assets/img/techex-landing-page/diagram.webp"} caption={"Digital Literacy"} />
        </div>
  );
}

export default Services;