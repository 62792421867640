import React from "react";
import {Link} from "react-router-dom";

const HeaderWrap = () => {
  return (
        <header className="header-wrap header-1">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-6 col-sm-5 col-xl-2">
                    <div className="logo">
                        <a href="/">
                            <img src="assets/img/logowhite.png" alt="logo" style={{height: "80px",width: "100px"}}/>
                        </a>
                    </div>
                    <div className="logo-2">
                        <a href="/">
                            <img src="assets/img/logowhite.png" alt="logo" style={{height: "60px",width: "80px"}}/>
                        </a>
                    </div>
                </div>
                <div className="col-xl-7 d-none d-xl-block">
                    <div className="main-menu">
                    <ul style={{ display: 'flex', listStyle: 'none', padding: 0 }}>
                        <li style={{marginRight: "10px"}}><Link to="/">Home</Link></li>
                        <li style={{marginRight: "10px"}}><Link to="/about">About</Link></li>
                        <li style={{marginRight: "10px"}}><Link to="/services">Services</Link></li>
                        <li style={{marginRight: "10px"}}><Link to="/products">Products</Link></li>
                        <li style={{marginRight: "10px"}}><a href="https://academy.abiatechhub.com/" target="_blank" rel="noopener noreferrer">Academy</a></li>
                        <li style={{marginRight: "10px"}}><a href="https://workspace.abiatechhub.com/" target="_blank" rel="noopener noreferrer">Workspace</a></li>
                        <li style={{marginRight: "10px"}}><a href="https://techflock.abiatechhub.com/" target="_blank" rel="noopener noreferrer">Events</a></li>
                        <li style={{marginRight: "10px"}}><a href="https://blog.abiatechhub.com/" target="_blank" rel="noopener noreferrer">Blog</a></li>
                        <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="mobile-nav-bar d-block col-sm-1 col-6 d-xl-none">
                    <div className="mobile-nav-wrap">                    
                        <div id="hamburger">
                            <i className="fal fa-bars" style={{fontSize: "20px"}}></i>
                        </div>
                        
                        <div className="mobile-nav">
                            <button type="button" className="close-nav">
                                <i className="fal fa-times-circle"></i>
                            </button>
                            <nav className="sidebar-nav">
                                <ul className="metismenu" id="mobile-menu">
                                    <li><Link to={"/"}>Home</Link></li>
                                    <li><Link to={"/about"}>About</Link></li>
                                    <li><Link to={"/services"}>Services</Link></li>
                                    <li><Link to={"/products"}>Products</Link></li>
                                    <li><a href="https://academy.abiatechhub.com/" target="_blank" rel="noopener noreferrer">Academy</a></li>
                                    <li><a href="https://workspace.abiatechhub.com/" target="_blank" rel="noopener noreferrer">Workspace</a></li>
                                    <li><a href="https://techflock.abiatechhub.com/" target="_blank" rel="noopener noreferrer">Events</a></li>
                                    <li><a href="https://blog.abiatechhub.com/" target="_blank" rel="noopener noreferrer">Blog</a></li>
                                    <li><Link to={"/contact"}>Contact</Link></li>
                                </ul>
                            </nav>

                            <div className="action-bar">
                                <a href="/"><i className="fal fa-envelope-open-text"></i>hello@abiatechhub.com</a>
                                <a href="tel:123-456-7890"><i className="fal fa-phone"></i>+234 805 274 9600</a>
                                <Link to={"/contact"} className="d-btn theme-btn black">Consultancy</Link>
                            </div>
                        </div>                            
                    </div>
                    <div className="overlay"></div>
                </div>
            </div>
        </div>
    </header>
  );
};

export default HeaderWrap;