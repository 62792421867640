import React from "react";
import GeneralHero from "../components/general/generalHero";
import AboutSubHero from "../components/about/aboutSubHero";
import CallToAction from "../components/general/callToAction";
import OurTeam from "../components/about/ourTeam";

const About = () => {
  return (
    <>
        <GeneralHero bgText={"about"} theme={"About"} subTheme={"about"} />

        <AboutSubHero />

        <OurTeam />
        
        <CallToAction />

    </>
  );
};

export default About;