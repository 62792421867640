import React from "react";

const ServicesCard = (props) => {
  return (
    <div className="col-xl-4 col-md-6 col-12" data-aos="fade-up">
        <div className="single-our-service style-2">
            <div className="thumb bg-cover" style={{backgroundImage: `url(${props.bgImgUrl})`}}></div>
            <div className="content">
                <div className="icon">
                    <img src={props.imgUrl} alt="Tech startups abia"/>
                </div>
                <h3><a href="/services">{props.caption}</a></h3>
            </div>
        </div>
    </div>
  );
};

export default ServicesCard;