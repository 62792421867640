import React from "react";
import Hero from "../components/home/hero";
import Second from "../components/home/second";

const Home = () => {
    return(
    <>
        <Hero />
        <Second />
    </>
    )
}
export default Home;