import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
<section className="cta-banner">
  <br />
  <br />
  <br />
  <br />
  <br />
  <div className='container-fluid bg-cover section-bg'>
    <div className="row">
      <div className="col-xl-7 text-white col-12 text-center text-xl-left">
        <h1 style={{ fontSize: '56px' }} className='mb-3'>404 - Oops!, you got lost</h1>
        <p>The page you are looking for does not exist.</p>
        <p>Please check the URL for any spelling mistakes or try searching for the page using the navigation menu.</p>
        <p>If you believe this is an error, please contact our support team.</p>
        <div className="col-xl-5 col-12 mb-2 mt-3">
        <Link to="/" className="theme-btn mt-4 mt-xl-0">Go to Home Page <i className="fas fa-arrow-right"></i></Link>
      </div>
      </div>
      <div className="col-xl-5 col-12">
        <img src="assets/img/ath-404.webp" alt="404" />
      </div>
    </div>
  </div>
</section>

  );
};

export default NotFound;
