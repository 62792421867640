import React from "react";

const Products = () => {
  return (
  <>
     <section className="page-banner-wrap bg-cover" style={{backgroundImage: "url('assets/img/about-banner.webp')"}}>
        <br />
        <br />
        <br />
        <div className="banner-text">Products</div>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <div className="page-heading text-white">
                        <div className="page-title">
                            <h1>Products</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="case-study-wrapper section-padding">
        <div className="container">
            <div className="row mb-50 align-items-center">
                <div className="col-12 col-md-5">
                    <div className="section-title">
                        <p>our Recent product</p>
                        <h1>Explore our awesome products</h1>
                    </div>
                </div>
                {/* <div className="col-12 col-md-7 mt-4 mt-md-0  text-md-right">
                    <div className="case-cat-filter">
                        <button data-filter="*" className="active">All</button>
                        <button data-filter=".business">Business</button>
                        <button data-filter=".consulting">Consulting</button>
                        <button data-filter=".product">Product</button>
                        <button data-filter=".engineering">Engineering</button>
                    </div>
                </div> */}
            </div>
            
            <div className="row grid">
                <div className="col-xl-4 col-md-6 grid-item business product engineering">
                    <div className="single-case-study">
                        <div className="features-thumb bg-cover" style={{backgroundImage: "url('assets/img/case/skooltool.webp')"}}></div>
                        <div className="content">
                            <h3>SKOOLTOOL</h3>
                            <p>A Comprehensive Solution for all Your Academic Needs.
                            Make your academic processes easier with our
                            advanced school management software.</p>
                            <a href="https://myskooltool.com/"><button
                            className="theme-btn mt-4 mt-xl-0 mb-0"
                            style={{ display: "flex", justifyContent: "center" }}>Explore</button></a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 grid-item product engineering">
                    <div className="single-case-study">
                        <div className="features-thumb bg-cover" style={{backgroundImage: "url('assets/img/case/ath-academy.webp')"}}></div>
                        <div className="content">
                            <h3>ACADEMY</h3>
                            <p>At Abia Tech Hub Academy, With our comprehensive courses and expert instructors, we empower students to embark on successful careers.</p>
                            <a href="https://academy.abiatechhub.com/"><button
                            className="theme-btn mt-4 mt-xl-0 mb-0"
                            style={{ display: "flex", justifyContent: "center" }}>Explore</button></a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 grid-item consulting product">
                    <div className="single-case-study">
                        <div className="features-thumb bg-cover" style={{backgroundImage: "url('assets/img/case/cretehost.webp')"}}></div>
                        <div className="content">
                            <h3>CRETEHOST</h3>
                            <p>CreteHost is a reliable web hosting platform, cretehost offers secure and scalable hosting solutions for businesses and individuals.</p>
                            <a href="https://cretehost.com/"><button
                            className="theme-btn mt-4 mt-xl-0 mb-0"
                            style={{ display: "flex", justifyContent: "center" }}>Explore</button></a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 grid-item consulting product">
                    <div className="single-case-study">
                        <div className="features-thumb bg-cover" style={{backgroundImage: "url('assets/img/case/abiatechhub.webp')"}}></div>
                        <div className="content">
                            <h3>Retail POS</h3>
                            <p> Retail POS is a versatile and elegant Point of Sale application with features like Invoicing, Inventory Management, Customer and Supplier Management, Project Management, Accounts, and more. It supports different tax types including Exclusive, Inclusive, and GST. </p>
                            <a href="https://pos.abiatechhub.com/"><button
                            className="theme-btn mt-4 mt-xl-0 mb-0"
                            style={{ display: "flex", justifyContent: "center" }}>Explore</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="cta-banner">
        <div className="container-fluid bg-cover section-bg" style={{backgroundImage: "url('assets/img/cta_bg1.png')"}}>
            <div className="cta-content">
                <div className="row align-items-center">
                    <div className="col-xl-7 text-white col-12 text-center text-xl-left">
                        <h1>Ready To Get Free Consulations For <br /> Any Kind Of IT Solutions ? </h1>
                    </div>
                    <div className="col-xl-5 col-12 text-center text-xl-right">
                        <a href="/contact" className="theme-btn mt-4 mt-xl-0">Get a quote <i className="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section> 
  </>
  );
}

export default Products;