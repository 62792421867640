import React from "react";

const SuccessItem = (props) => {
  return (
    <div className="success-item">
        <div className="thumb bg-cover" style={{backgroundImage: `url(${props.imgUrl})`}}></div>
        <div className="content d-flex align-items-center justify-content-between">
            <div className="text">
                <h3>{props.head}</h3>
                <p>{props.subHead}</p>
            </div>

            <div className="icon">
                <i className="icon-arrow-right-1"></i>
            </div>
        </div>
    </div>
  );
};

export default SuccessItem;
